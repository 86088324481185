* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  width: 100vw;
  background-color: rgb(239, 242, 255);
  background-image: url(../../assets/background_img.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  position: absolute;
  height: 90vh;
  width: 90vw;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2rem;
  gap: 2rem;
  overflow: scroll;
}
.container1 {
  overflow: hidden;
  border: 0.2rem solid black;
  border-radius: 5px;
}
.left,
.right {
  border: 1px solid #000;
  border-radius: 5px;
  display: block;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
  transition: opacity 2s ease-in-out;
}

.panel {
  position: relative;
  top: 0;
  height: 90vh;
  width: 90vw;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2rem;
  gap: 2rem;
  overflow: scroll;
  transition: 1.1s ease-in-out;
}
.panel::-webkit-scrollbar,
.container::-webkit-scrollbar {
  display: none;
}
.left_panel,
.right_panel {
  border: 1px solid #000;
  border-radius: 5px;
  display: block;
  justify-content: center;
  height: 100%;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
  transition: opacity 2s ease-in-out;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #61a5ff;
  transition: 1.1s ease-in-out;
  opacity: 1;
  pointer-events: all;
}

.right_panel .content h3,
.left_panel .content h3 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  margin-top: 5rem;
  transition: 1.1s ease-in-out;
}

.right_panel .content p,
.left_panel .content p {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  transition: 1.1s ease-in-out;
}
.left_panel img,
.right_panel img {
  height: 20rem;
  width: 30rem;
  transition: 1.1s ease-in-out;
}

.right_panel img {
  margin-top: 4rem;
  transition: 1.1s ease-in-out;
}

.right_panel .content button,
.left_panel .content button {
  width: 10rem;
  height: 3rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: 400;
  color: white;
  background-color: #01138f;
  margin-bottom: 3rem;
}
.right_panel .content button {
  margin-bottom: 0rem;
}

.left_first,
.right_first {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 2rem;
}

.left_first .left_first_img,
.right_first .right_first_img {
  height: 2rem;
  width: 2rem;
  margin: 0.5rem;
}

.left_first span,
.right_first span {
  font-size: 1.2rem;
  color: rgb(0, 0, 128);
}

/* Your CSS Styles */
.left_second,
.right_second {
  width: 100%;
  /* border: 1px solid black; */
  margin-top: 0.5rem;
  text-align: center;
}

.left_second h2,
.right_second h2 {
  width: 100%;
  font-size: 3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  color: #01138f;
}

.left_second .login_p,
.right_second .register_p {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.social_net {
  width: 100%;
  height: 4rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.social_net .Link {
  text-decoration: none;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
}

.social_net img {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  scale: 0.9;
}

.left_second .Or,
.right_second .Or {
  width: 100%;
  margin-bottom: 1rem;
  /* border: 1px solid black; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 400;
}

/* Your existing CSS */
.left_second .Or::before,
.left_second .Or::after,
.right_second .Or::before,
.right_second .Or::after {
  content: "";
  height: 0.1rem;
  width: 50%;
  margin: 1rem;
  background-color: rgb(90, 90, 90);
  display: inline-block; /* Make the lines inline with the text */
}

form {
  width: 80%;
  height: auto;
  /* border: 1px solid black; */
  margin: 0 auto; /* Center the form horizontally */
}

.input_field {
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid rgb(0, 0, 0);
  margin-bottom: 1.5rem;
  background-color: #f0ffff;
  border-radius: 2rem;
}

/* .input_field label {
    margin: 1rem;
    font-size: 1.2rem;
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  } */
/* Your existing CSS */

.input_field input {
  font-size: 1rem;
  margin-left: 1rem;
  outline: none;
  border: none;
  padding: 1px;
  height: 100%;
  width: 65%;
  background: transparent;
}

.input_field img {
  position: relative;
  height: 2rem;
  margin: 1rem;
  cursor: pointer;
}

.more_opt {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #242323;
  gap: 1.5rem;
}

.remember,
.forget {
  width: 100%;
  height: 3rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0ffff;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 2rem;
  padding: 1rem;
}

.more_opt .remember input[type="checkbox"] {
  accent-color: rgb(0, 0, 127);
  scale: 1.5;
}

.more_opt .forget a {
  text-decoration: none;
  color: #01138f;
  font-size: 1.3rem;
}
/* Your existing CSS */

.more_opt .forget a::after {
  content: "";
  display: block;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0.1rem;
  background-color: #01138f;
  transition: all 0.5s ease;
}

.more_opt .forget a:hover::after {
  width: 100%;
}

.left_second form button,
.right_second form button {
  width: 50%;
  height: 3rem;
  cursor: pointer;
  border-radius: 2rem;
  border: 1px solid #000e66;
  outline: none;
  font-size: 1.5rem;
  color: #fff;
  background-color: #006dff;
  margin-bottom: 1.5rem;
}

.sign_up,
.sign_in {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  /* border: 1px solid black; */
  margin-bottom: 1.5rem;
}

.sign_p {
  font-size: 1.2rem;
}

.sign_a {
  text-decoration: none;
  color: #000e66;
  font-size: 1.2rem;
}

.sign_a::after {
  content: "";
  display: block;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0.1rem;
  background-color: #01138f;
  transition: all 0.5s ease;
}

.sign_a:hover::after {
  width: 100%;
}

.panel1 {
  transform: translateX(49.08%);
  border: none;
  z-index: 1;
  background: none;
  pointer-events: all;
}
.panel2 {
  transform: translateX(0%);
  border: none;
  z-index: 1;
  background: none;
  pointer-events: none;
}
.left_panel1 {
  display: block;
}
.right_panel1 {
  display: none;
}
.left_panel2 {
  display: none;
}
.right_panel2 {
  display: block;
}
