@media screen and (max-width: 1919px) {
  .panel1 {
    transform: translateX(48.9%);
  }
}
@media screen and (max-width: 1495px) {
  .panel1 {
    transform: translateX(48.7%);
  }
}
@media screen and (max-width: 1338px) {
  .more_opt .forget a,
  .more_opt label {
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 1288px) {
  .more_opt .forget a,
  .more_opt label {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 1280px) {
  .container {
    position: relative;
    grid-template-columns: 1fr;
    height: auto;
  }
  .panel {
    position: relative;
    display: none;
  }
  .left1 {
    display: block;
    border: 1px solid black;
  }
  .left2 {
    display: none;
    border: 1px solid black;
  }
  .right1 {
    display: block;
    border: 1px solid black;
  }
  .right2 {
    display: none;
    border: 1px solid black;
  }

  .sign_in,
  .sign_up {
    display: flex;
  }
  .sign_a,
  .sign_p {
    margin-top: 2rem;
  }
  .form {
    height: auto;
  }
}
@media screen and (max-width: 1024px) {
  .left_second,
  .right_second {
    margin-top: 1rem;
    border: none;
  }
  .social_net {
    height: 3.5rem;
  }
  .social_net .Link {
    height: 3.5rem;
    width: 3.5rem;
  }
  .social_net img {
    height: 3.5rem;
    width: 3.5rem;
  }

  .left_second .Or,
  .right_second .Or {
    font-size: 2rem;
  }
  .left_second span::before,
  .left_second span::after,
  .right_second span::before,
  .right_second span::after {
    content: "";
    height: 0.1rem;
  }
  .input_field {
    height: 2.5rem;
  }
  .input_field img {
    height: 2rem;
  }

  .remember,
  .forget {
    height: 2.5rem;
  }
  .more_opt .forget a {
    font-size: 1.2rem;
  }
  .more_opt label {
    font-size: 1.2rem;
  }
  .more_opt .remember input[type="checkbox"] {
    scale: 1.5;
  }
  .left_second form button,
  .right_second form button {
    height: 3rem;
    font-size: 1.5rem;
  }
  .container {
    height: auto;
  }
}
@media screen and (max-width: 684px) {
  .more_opt {
    display: block;
    width: 100%;
  }
  .remember,
  .forget {
    margin-bottom: 1.5rem;
  }
  .left_second h2,
  .right_second h2 {
    font-size: 2rem;
  }

  .left_second,
  .right_second {
    margin-top: 3rem;
    padding: 0;
  }

  .left_first,
  .right_first {
    margin-top: 1rem;
  }

  .left_second .login_p,
  .right_second .register_p {
    font-size: 1rem;
  }
  .left,
  .right {
    padding: 0;
    margin: 0;
  }
  .container {
    padding: 0;
    margin: 0;
  }
  .left_first .left_first_img,
  .right_first .right_first_img {
    height: 1.5rem;
    width: 1.5rem;
  }
  .left_first span,
  .right_first span {
    font-size: 1.2rem;
  }
  .container {
    height: 100vh;
    width: 100%;
    margin: 0;
    border: none;
  }
  .container1 {
    padding: 0;
    margin: 0;
    border: none;
  }
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: block;
    height: 100%;
  }
  .sign_a,
  .sign_p {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 375px) {
  .left,
  .right {
    border: none;
    height: auto;
  }
  .left_second,
  .right_second {
    margin-top: 1rem;
    border: none;
  }
  .social_net {
    height: 2.5rem;
  }
  .social_net .Link {
    height: 2.5rem;
    width: 2.5rem;
  }
  .social_net img {
    height: 2.5rem;
    width: 2.5rem;
  }

  .left_second .Or,
  .right_second .Or {
    font-size: 1.5rem;
  }
  .left_second span::before,
  .left_second span::after,
  .right_second span::before,
  .right_second span::after {
    content: "";
    height: 0.05rem;
  }
  .input_field {
    height: 2rem;
  }
  .input_field img {
    height: 1rem;
  }

  .remember,
  .forget {
    height: 2rem;
  }
  .more_opt .forget a {
    font-size: 1rem;
  }
  .more_opt label {
    font-size: 1rem;
  }
  .more_opt .remember input[type="checkbox"] {
    scale: 1.2;
  }
  .left_second form button,
  .right_second form button {
    height: 2rem;
    font-size: 1rem;
  }
  .sign_a,
  .sign_p {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 326px) {
  .sign_a,
  .sign_p {
    margin-top: 2rem;
    font-size: 1.1rem;
  }
}
